import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import SectionTitle from "../../../common/shared/SectionTitle";
import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";
import logo from "../../../assets/img/gba-logo.png";
import moment from "moment";
import { useLocation } from "react-router-dom";
import DuesPayment from "../components/DuesPayment";
import ManualPaymentButton from "../components/ManualPaymentButton";
import { normalDues } from "../../../redux/actions/dues_action";
import UpdatePaymentButton from "../components/UpdatePaymentButton";
import RegionDuesButton from "./RegionDuesButton";
import jsPDF from "jspdf";
import toast from "react-hot-toast";
import ReactToPrint from "react-to-print";

function DuesInvoice() {
  const [type, setType] = useState("");
  const [open, setOpen] = useState(false);

  const location = useLocation();

  const handleOpen = () => setOpen(!open);
  const handleType = (name) => setType(name);

  const user = useSelector((state) => state.auth?.userData);

  const duesData = location.state?.formData.data;

  const baseAmount =
    duesData.title === "dues"
      ? duesData.data.amount
      : duesData.title === "outstanding"
      ? duesData.amount
      : duesData.region.amount;

  const multiplier = !type ? 0 : type === "electronic" ? 0.02 : 0.03;

  let cardBodyRef = useRef();

  // const handleGeneratePDF = () => {
  //   const content = document.getElementById("content-to-pdf");
  //   const doc = new jsPDF("p", "pt", "a4");

  //   doc.html(content, {
  //     callback: function (doc) {
  //       const pdfBlob = doc.output("blob");
  //       const pdfUrl = URL.createObjectURL(pdfBlob);

  //       const printWindow = window.open(pdfUrl, "_blank");

  //       if (printWindow) {
  //         printWindow.onload = () => {
  //           printWindow.print();
  //         };
  //       }
  //       // doc.autoPrint();
  //       // window.open(doc.output("bloburl"), "_blank");
  //       // doc.save();
  //       // toast.success(
  //       //   "Invoice downloaded successfully. Please check your download list",
  //       //   { duration: 5000 }
  //       // );
  //       setOpen(false);
  //     },
  //     margin: [10, 10, 10, 10],
  //     html2canvas: {
  //       scale: 0.62,
  //       logging: false,
  //       useCORS: true,
  //     },
  //     x: 10,
  //     y: 10,
  //     width: 180,
  //     windowWidth: 900,
  //   });
  // };

  return (
    <div className="my-8 max-w-[1200px] m-auto px-4">
      <SectionTitle title="Dues Invoice" description="" children={[]} />

      <Typography className="text-center mb-4" variant="h4">
        Please select invoice type to generate invoice and proceed to make
        payment{" "}
      </Typography>
      <div className="flex items-center justify-center gap-3 mb-8">
        <Button
          color="green"
          onClick={() => handleType("manual")}
          fullWidth
          size="lg"
          className="text-sm sm:text-lg md:text-2xl"
        >
          Manual Payment Invoice
        </Button>
        <Button
          color="light-blue"
          onClick={() => handleType("electronic")}
          fullWidth
          size="lg"
          className="text-base sm:text-lg md:text-2xl"
        >
          E-Payment Invoice
        </Button>
        {/* <div>
          <Button onClick={handleOpen}>Print Invoice</Button>
        </div>
        {duesData.title === "dues" ||
        duesData.title === "women" ||
        duesData.title === "forum" ? (
          <>
            <DuesPayment
              data={duesData.data}
              amount={duesData.data.amount}
              year={duesData.data?.year}
              id={duesData?.data?.id}
              post_type="dues"
            />
            <ManualPaymentButton
              amount={duesData?.data?.amount}
              year={duesData?.data?.year}
              id={duesData?.data?.id}
              post_type="dues"
              apiFxn={normalDues}
            />
          </>
        ) : duesData.title === "outstanding" ? (
          <UpdatePaymentButton
            data={duesData.data}
            amount={duesData.amount}
            id={duesData.data.id}
          />
        ) : (
          <>
            <RegionDuesButton
              data={duesData.data}
              amount={duesData.region.amount}
              year={duesData?.data?.year}
              id={duesData?.data?.id}
              post_type="regdues"
              sub_type={duesData.region?.name}
            />
            <ManualPaymentButton
              amount={duesData.region?.amount}
              year={duesData?.data?.year}
              id={duesData?.data?.id}
              post_type="regdues"
              apiFxn={normalDues}
              name={duesData.region.name}
              check={duesData?.data?.receipts?.some(
                (item) =>
                  item.post_type === "regdues" &&
                  item.sub_type === duesData.region.name &&
                  duesData.status === 0
              )}
            />
          </>
        )} */}
      </div>

      <div className="flex flex-col items-center justify-center mb-8">
        <Typography variant="h5" color="red" className="text-center mb-4">
          Electronic Payment attracts a payment charge of 2% (GH¢
          {duesData.title === "dues"
            ? parseFloat((duesData.data.amount * 0.02).toFixed(2))
            : duesData.title === "outstanding"
            ? parseFloat((duesData.amount * 0.02).toFixed(2))
            : parseFloat((duesData.region.amount * 0.02).toFixed(2))}
          )
        </Typography>
        <Typography variant="h5" color="red" className="text-center">
          Manual Payment attracts a payment charge of 3% (GH¢
          {duesData.title === "dues"
            ? parseFloat(duesData.data.amount * 0.03).toFixed(2)
            : duesData.title === "outstanding"
            ? parseFloat(duesData.amount * 0.03).toFixed(2)
            : parseFloat(duesData.region.amount * 0.03).toFixed(2)}
          )
        </Typography>
      </div>

      {type && (
        <>
          <div className="flex items-center justify-center gap-3 mb-8">
            <ReactToPrint
              trigger={() => <Button>Print Invoice</Button>}
              content={() => cardBodyRef}
              removeAfterPrint={false}
            />
            {type === "electronic" ? (
              duesData.title === "dues" ||
              duesData.title === "women" ||
              duesData.title === "forum" ? (
                <DuesPayment
                  data={duesData.data}
                  amount={duesData.data.amount}
                  year={duesData.data?.year}
                  id={duesData?.data?.id}
                  post_type="dues"
                  inText={true}
                />
              ) : duesData.title === "outstanding" ? (
                <UpdatePaymentButton
                  data={duesData.data}
                  amount={duesData.amount}
                  id={duesData.data.id}
                />
              ) : (
                <RegionDuesButton
                  data={duesData.data}
                  amount={duesData.region.amount}
                  year={duesData?.data?.year}
                  id={duesData?.data?.id}
                  post_type="regdues"
                  sub_type={duesData.region?.name}
                />
              )
            ) : duesData.title === "dues" ||
              duesData.title === "women" ||
              duesData.title === "forum" ? (
              <ManualPaymentButton
                amount={duesData?.data?.amount}
                year={duesData?.data?.year}
                id={duesData?.data?.id}
                post_type="dues"
                apiFxn={normalDues}
                inText={true}
              />
            ) : (
              <ManualPaymentButton
                amount={duesData.region?.amount}
                year={duesData?.data?.year}
                id={duesData?.data?.id}
                post_type="regdues"
                apiFxn={normalDues}
                name={duesData.region.name}
                inText={true}
                check={duesData?.data?.receipts?.some(
                  (item) =>
                    item.post_type === "regdues" &&
                    item.sub_type === duesData.region.name &&
                    duesData.status === 0
                )}
              />
            )}
          </div>

          <Card>
            {/* <CardBody ref={(el) => (cardBodyRef = el)}> */}
            <CardBody ref={(el) => (cardBodyRef = el)}>
              <img src={logo} alt="logo" className="h-20" />

              <div className="mt-5 pl-3 flex flex-col md:flex-row gap-8 justify-between">
                <div>
                  <Typography variant="h5">Ghana Bar Association</Typography>
                  <Typography variant="paragraph">
                    H/NO. 5, 2nd Ave. State House Road,
                  </Typography>
                  <Typography variant="paragraph">
                    South-East Ridge, Adjacent to
                  </Typography>
                  <Typography variant="paragraph">
                    Ghana International Press Centre,
                  </Typography>
                  <Typography variant="paragraph">Accra</Typography>
                </div>

                <div>
                  <Typography variant="h5">
                    Billed to:{" "}
                    {user.title + " " + user.first_name + " " + user.last_name}
                  </Typography>
                  <Typography variant="paragraph">
                    Email Address: {user.email}
                  </Typography>
                  <Typography variant="paragraph">
                    Phone #: {user.phone}
                  </Typography>

                  <div className="mt-20">
                    <Typography variant="h6" color="gray">
                      Invoice Date
                    </Typography>
                    <Typography variant="h4">
                      {moment().format("MMMM Do, YYYY")}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="mt-24   overflow-x-auto">
                <table className="w-full min-w-max table-auto text-left">
                  <thead>
                    <tr>
                      <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          Description
                        </Typography>
                      </th>
                      <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          Qty
                        </Typography>
                      </th>
                      <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          Amount
                        </Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {duesData.data.name} -{" "}
                          {duesData.title === "dues"
                            ? "National"
                            : duesData.title === "outstanding"
                            ? "Outstanding"
                            : duesData.title === "women"
                            ? "Women Dues"
                            : duesData.title === "in-house_forum"
                            ? "In-House Forum"
                            : duesData.title}
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          1
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {duesData.title === "dues"
                            ? duesData.data.amount
                            : duesData.title === "outstanding"
                            ? duesData.amount
                            : duesData.region.amount}
                        </Typography>
                      </td>
                    </tr>

                    {type && (
                      <tr>
                        <td className="p-4 border-b border-blue-gray-50">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {type === "electronic"
                              ? "E-Payment"
                              : "Manual Payment"}{" "}
                            Processing Charges
                          </Typography>
                        </td>

                        <td className="p-4 border-b border-blue-gray-50">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            1
                          </Typography>
                        </td>

                        <td className="p-4 border-b border-blue-gray-50">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {type === "electronic" && duesData.title === "dues"
                              ? parseFloat(duesData.data.amount * 0.02).toFixed(
                                  2
                                )
                              : type === "manual" && duesData.title === "dues"
                              ? parseFloat(duesData.data.amount * 0.03).toFixed(
                                  2
                                )
                              : type === "electronic" &&
                                duesData.title !== "dues"
                              ? parseFloat(
                                  duesData.region.amount * 0.02
                                ).toFixed(2)
                              : type === "manual" && duesData.title !== "dues"
                              ? parseFloat(
                                  duesData.region.amount * 0.03
                                ).toFixed(2)
                              : 0}
                          </Typography>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-semibold"
                        >
                          Total
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          GH¢
                        </Typography>
                      </td>

                      <td className="p-4 border-b border-blue-gray-50">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-semibold"
                        >
                          {parseFloat(baseAmount * (1 + multiplier)).toFixed(2)}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mt-40">
                <Typography variant="h4">Thank you!</Typography>
                <Typography variant="paragraph">
                  If you encounter any issue related to the invoice you can
                  contact us at:
                </Typography>

                <Typography variant="paragraph">
                  email:{" "}
                  <span className="font-semibold">info@ghanabar.org</span>
                </Typography>
              </div>
            </CardBody>
          </Card>
        </>
      )}

      {open && (
        <Dialog open={open} size="sm" handler={handleOpen}>
          <DialogHeader>Inovice Type</DialogHeader>
          <DialogBody>
            <Typography color="black" variant="lead" className="mb-2">
              Please select invoice type.
            </Typography>
          </DialogBody>
          <DialogFooter className="flex justify-between">
            <Button
              // onClick={() => handleGeneratePDF("electronic")}
              color="pink"
            >
              Electronic Payment Invoice
            </Button>

            <Button color="amber">Manual Payment Invoice</Button>
          </DialogFooter>
        </Dialog>
      )}
    </div>
  );
}

export default DuesInvoice;
