import {
  Button,
  Card,
  CardBody,
  Chip,
  Typography,
} from "@material-tailwind/react";
import React, { useRef, useState } from "react";
import moment from "moment";
import welcome from "../../../assets/img/welcome.jpg";
import { useNavigate } from "react-router-dom";
import OpenModal from "../../../common/actions/OpenModal";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";

function UserBanner() {
  const [code, setQrcode] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const user = JSON.parse(localStorage.getItem("profile")).user;
  let cardBodyRef = useRef();

  const navigate = useNavigate();

  const handleCode = () => setQrcode(!code);

  const handleBrochure = () => setShowModal(!showModal);
  // ;

  return (
    <>
      <div className="relative overflow-hidden">
        <img
          src={welcome}
          alt="welcome"
          className=" h-[350px] w-screen object-cover brightness-50"
        />
      </div>
      <div className="max-w-[1200px] mx-auto px-4">
        <div className="mt-[-100px]">
          <Card className="w-full">
            <CardBody className="flex flex-col lg:flex-row">
              <div className="lg:w-[220px] lg:mr-4 rounded-lg overflow-clip mb-4 lg:mb-0 w-full">
                <img
                  src={
                    user.bm_data
                      ? `https://storage-gba.s3.eu-west-1.amazonaws.com/bm/${user.bm_data}`
                      : "./default.jpg"
                  }
                  height="160"
                  width="160"
                  alt=""
                />
              </div>
              <div className="w-full">
                <div className="flex justify-between">
                  <div>
                    <span>Name</span>
                    <Typography variant="h5" color="black">
                      {user.title +
                        " " +
                        user.first_name +
                        " " +
                        user.last_name}
                    </Typography>
                  </div>
                  <div className="hidden w-[400px] sm:flex gap-2">
                    <Button
                      onClick={() => navigate("/home/update")}
                      className=" h-fit  w-full   my-8 md:my-0 "
                    >
                      Edit Profile
                    </Button>

                    {user.conference &&
                      user.conference.sub_type === "In-Person" && (
                        <Button
                          onClick={handleCode}
                          className="h-fit w-full  my-8 md:my-0 "
                          color="amber"
                        >
                          Access Code
                        </Button>
                      )}

                    {user.conference && (
                      <Button
                        onClick={handleBrochure}
                        className="h-fit w-full  my-8 md:my-0 "
                        color="pink"
                      >
                        Conference Documents
                      </Button>
                    )}
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row items-center justify-between mt-4">
                  <div className="w-full lg:w-1/2 lg:pr-2 mb-2 lg:mb-0">
                    <span>Bar Number</span>
                    <Typography variant="lead" color="black">
                      {user.bar_number}
                    </Typography>
                  </div>
                  <div className="w-full lg:w-1/2 lg:pl-2">
                    <span>Date Of Call</span>
                    <Typography variant="lead" color="black">
                      {moment(user.date_of_call).format("MMMM Do YYYY")}
                    </Typography>
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row items-center justify-between mt-4">
                  {user.chamber && (
                    <div className="w-full lg:w-1/2 lg:pr-2 mb-2 lg:mb-0">
                      <span>Chamber Name</span>
                      <Typography variant="lead" color="black">
                        {user.chamber}
                      </Typography>
                    </div>
                  )}

                  <div className="w-full lg:w-1/2">
                    <span>Status</span>
                    <div className="flex">
                      {user.standing === true ? (
                        <Chip
                          variant="ghost"
                          color="green"
                          size="sm"
                          value="In Good Financial Standing"
                        />
                      ) : (
                        <Chip
                          variant="ghost"
                          color="red"
                          size="sm"
                          value="Not in Good Financial Standing"
                        />
                      )}
                    </div>
                  </div>

                  <Button
                    onClick={() => navigate("/home/update")}
                    className=" h-fit w-full sm:hidden  mt-5 md:my-0 "
                  >
                    Edit Profile
                  </Button>
                  {user.conference &&
                    user.conference.sub_type === "In-Person" && (
                      <Button
                        className="h-fit w-full mt-2  sm:hidden  md:my-0 "
                        color="amber"
                        onClick={handleCode}
                      >
                        Access Code
                      </Button>
                    )}
                  {user.conference && (
                    <Button
                      className="h-fit w-full mt-2 sm:hidden md:my-0"
                      color="pink"
                      onClick={handleBrochure}
                    >
                      Conference Documents
                    </Button>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

      {code && (
        <OpenModal
          open={code}
          handler={handleCode}
          // title="Conference Code"
          children={
            <>
              <div class="flex justify-center items-center mb-6">
                <div class="text-center">
                  <ReactToPrint
                    trigger={() => <Button>Print Code</Button>}
                    content={() => cardBodyRef}
                    removeAfterPrint={false}
                  />
                </div>
              </div>

              <div
                ref={(el) => (cardBodyRef = el)}
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 200,
                  width: "100%",
                }}
              >
                <QRCode
                  size={256}
                  className="mt-12"
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                  value={user.bar_number}
                  // viewBox={`0 0 256 256`}
                />
              </div>
            </>
          }
        />
      )}
    </>
  );
}

export default UserBanner;
